<template>
  <transition name="fade">
    <div v-if="pending" class="flex center bg-grey-90" style="height: 34rem">
      <vf-loader class="c-grey-30" style="height:8rem" />
    </div>
    <div
      v-else-if="data"
      class="gap-4 md:flex divide-grey-70 c-grey-10 <md:divide-y"
      data-test-id="vf-recommendations-widget"
    >
      <div class="space-y-8 divide-y divide-grey-70 ">
        <section v-if="relatedItems.length" data-test-id="vf-recommendations-widget-related-items">
          <h4 class="mb-4 title-2">
            {{ $t.recommendations.relatedItems }}
          </h4>
          <ul class="space-y-4 ">
            <li v-for="{ anchor, url } in relatedItems" :key="anchor">
              <vf-link :to="url">
                {{ anchor }}
              </vf-link>
            </li>
          </ul>
        </section>
        <section
          v-if="relatedCategories.length"
          :class="{ 'pt-8 ': relatedItems }"
          data-test-id="vf-recommendations-widget-related-categories"
        >
          <h4 class="mb-4 title-2">
            {{ $t.recommendations.relatedCategories }}
          </h4>
          <ul class="space-y-4 ">
            <li v-for="{ anchor, url } in relatedCategories" :key="anchor">
              <vf-link :to="url">
                {{ anchor }}
              </vf-link>
            </li>
          </ul>
        </section>
      </div>
      <section
        v-if="relatedProducts.length"
        class="grow <md:mt-12"
        data-test-id="vf-recommendations-widget-related-products"
      >
        <h4 class="mb-4 <md:mt-12 title-2">
          {{ $t.recommendations.relatedProducts }}
        </h4>
        <vf-carousel class-container="-mx-2 " class-controls="-mt-12 <md:hidden">
          <div
            v-for="{ url, image, title, shortDescription } in relatedProducts"
            :key="url"
            class="relative w-1/2 f-col px-2 lg:w-1/4 md:w-1/3 "
          >
            <base-picture :alt="title" :src="image" v-bind="mediaSize" />
            <base-link class="line-clamp-2 mt-2 grow fw-bold" :to="url">
              <span class="absolute-0 z-1" />
              {{ title }}
            </base-link>
            <p class="line-clamp-4 mt-4 c-grey-20 md:line-clamp-2 !c-grey-10">
              {{ shortDescription }}
            </p>
          </div>
        </vf-carousel>
      </section>
    </div>
    <div v-else data-test-id="vf-recommendations-widget" />
  </transition>
</template>

<script lang="ts" setup>
import type { ProductWidgetRecommendationsData } from '#root/api/clients/product/data-contracts'

const { type } = withDefaults(defineProps<{
  type?: 'product' | 'category'
}>(), {
  type: 'product'
})

const { apiBaseUrl } = useFeatureFlags().configApiDomains
const { targetEnv } = useRuntimeConfig().public
// the media size should be consistent with the product card
const { mediaSize } = useAppConfig().components.product.card
const { fullPath } = useRoute()

const baseUrl = targetEnv === 'PROD'
  ? useOrigin()
  : apiBaseUrl.replace('/api', '')

const { data, error, pending } = await useApi().products.productWidgetRecommendations({
  ptype: type,
  url: `${baseUrl}${fullPath}`,
})
if (!data.value && error.value) log.error(`Recommendation Widget: ${error.value.message}`)

const { relatedItems, relatedProducts, relatedCategories } = (data.value || {}) as ProductWidgetRecommendationsData
</script>
